/**
 * Created by m.g. on 05.07.2016.
 */
(function(){
    var sffApp = angular.module('SffLandingpageApp', [
        'ngRoute',
        'ngSanitize',
        'ngMessages',
        'ui.bootstrap'
    ]);

    function MainController() {
        var vm = this;
    }

    function FormController($scope, $q, crudService, dataService) {
        var vm = this;
        var dayDate = [2016, 8, 16];
//Temporary Creation Stuff
//=====================================================
        var courseMinLength = 30;
        var trackTimes = [
            [12, 30],
            [13, 0],
            [13, 30],
            [14, 0],
            [14, 30],
            [15, 0]
        ];
        var courseIds = [
            "5784ecbefe4c59400e5ce4f4",
            "5784ecd1fe4c59400e5ce4f5",
            "5784ece2fe4c59400e5ce4f6"
        ];

        function createCourseDates() {
            var courseDatePromises = [];
            for (var i = 0; i < courseIds.length; i++) {
                var courseId = courseIds[i];

                for (var j = 0; j < trackTimes.length; j++) {
                    var trackTime = trackTimes[j];
                    var courseDateModel = {
                        courseid: courseId,
                        startsat: new Date(
                            dayDate[0], dayDate[1], dayDate[2],
                            trackTime[0], trackTime[1]),
                        endsat: new Date(
                            dayDate[0], dayDate[1], dayDate[2],
                            trackTime[0], trackTime[1] + courseMinLength)
                    };

                    courseDatePromises.push(crudService.createCourseDate(courseDateModel))
                }
            }

            $q.all(courseDatePromises)
                .then(function (success) {
                    console.log("success", success)
                }, function (error) {
                    console.log("error", error)
                })
        }

        vm.createCourseDates = createCourseDates;

//Temporary Creation Stuff
//=====================================================
        vm.inputData = {
            firstname: "",
            lastname: "",
            company: "",
            email: "",
            signed: false
        };
        vm.registeredUser = {};
        vm.userRegistered = false;
        vm.userRegisteredMessage = "";
        vm.userRegisteredEmail = "";

        //helper function
        function obfuscateEmail(emailText) {
            var firstPart = emailText.substr(0, emailText.indexOf('@') + 1);
            //minus one instead of two (count of characters which are not replaced) because
            //array.join already contains one less
            var lengthToBeReplaced = firstPart.length - 1;
            var obfuscatedFirstPart = firstPart.substr(0, 1) + new Array(lengthToBeReplaced).join('*') + firstPart.substr(lengthToBeReplaced, firstPart.length);
            var obfuscation = "<span class='hide'>null</span>";
            var lastPart = emailText.substr(emailText.indexOf('@') + 1, emailText.length);
            var obfuscatedEmail = obfuscatedFirstPart + obfuscation + lastPart;

            return obfuscatedEmail;
        }

        function signup() {
            if (vm.formOptions.$valid) {
                //check if user already exists
                crudService.readUserByEmail(vm.inputData.email)
                    .then(function (success) {
                        vm.registeredUser = success.data
                    }, function (error) {
                        console.log("Couldn't find a user", error)
                    }).finally(function () {

                    if (_.isEmpty(vm.registeredUser)) {
                        //create one if user doesn't exist
                        crudService.createUser(vm.inputData)
                            .then(function (success) {
                                vm.registeredUser = success.data;
                                console.log(success);
                            }, function (error) {
                                console.log("Couldn't create user", error);

                            }).finally(function () {
                            //save user to service
                            dataService.user = vm.registeredUser;
                            vm.userRegisteredMessage = "Sie wurden mit der folgenden Email-Adresse registriert:";
                            vm.userRegistered = true;
                        })
                    } else {
                        //save user to service
                        dataService.user = vm.registeredUser;
                        vm.userRegisteredMessage = "Sie sind bereits registriert mit der folgenden Email-Adresse:";
                        vm.userRegistered = true;
                    }
                    //hide parts of the email
                    vm.userRegisteredEmail = obfuscateEmail(vm.registeredUser.email);
                    //in order to utilize the animation of bootstraps spyscroll
                    $('#schedule-link').click();
                });

            } else if (vm.inputData.signed && vm.formOptions.$invalid) {
                //show form-validation
                vm.formOptions.$pristine = false;
                vm.formOptions.firstname.$pristine = false;
                vm.formOptions.lastname.$pristine = false;
                vm.formOptions.company.$pristine = false;
                vm.formOptions.email.$pristine = false;
            }
        }

        vm.signup = signup;
    }

    function ScheduleController($scope, $q, crudService, dataService) {
        var vm = this;
        var PODIUM_COURSE_ID = "5785f390ee54ed302521e982";
        var APERO_COURSE_ID = "5785f3a7ee54ed302521e983";
        var existingCourseRegistrations = [];
        vm.courseDatesReady = false;
        vm.scheduleCommitted = false;
        vm.scheduleCommittedText = "";
        vm.rows = [
            {label: "Zeit"},
            {label: "12:30", hour: 12, minute: 30},
            {label: "13:00", hour: 13, minute: 0},
            {label: "13:30", hour: 13, minute: 30},
            {label: "14:00", hour: 14, minute: 0},
            {label: "14:30", hour: 14, minute: 30},
            {label: "15:00", hour: 15, minute: 0},
            {label: "15:30", hour: 15, minute: 30},
            {label: "16:00", hour: 16, minute: 0},
            {label: "16:30", hour: 16, minute: 30},
            {label: "17:00", hour: 17, minute: 0}
        ];
        vm.columns = [
            {label: "Zeit"},
            {label: "Raum 1"},
            {label: "Raum 2"},
            {label: "Raum 3"},
            {label: "Raum 4"},
            {label: "Raum 5"},
            {label: "Track 1"},
            {label: "Track 2"},
            {label: "Track 3"},
            {label: "Road 11"}
        ];
        vm.selectedCourses = [];
        vm.currentUser = dataService.user;
        vm.disabledMessage = "Bitte registrieren Sie sich zuerst ";

        //First fetch the registered course-dates
        crudService.getCourseDates()
            .then(function (success) {
                dataService.saveCourseDates(success.data);
                vm.courseDates = success.data;
            }, function (error) {
                console.log(error)
            }).finally(function () {
            //then get all available courses
            crudService.getCourses()
                .then(function (success) {
                    vm.courses = success.data;
                }, function (error) {
                    console.log("Error while fetching 'Courses'", error)
                })
                .finally(function () {
                    //check if course is available
                    crudService.getCourseRegistrations()
                        .then(function (success) {
                            existingCourseRegistrations = success.data;
                        }, function (error) {
                            console.log("Error while fetching course-registrations", error);
                        })
                        .finally(function () {
                            //add properties of the 'course' to the 'courseDate' & get current registration count
                            vm.courseDates = _.map(vm.courseDates, function (courseDate) {
                                var existingRegistrationsCount = 0;
                                var givenCourse = _.findWhere(vm.courses, {_id: courseDate.courseid});
                                //add additional info for each course
                                courseDate.location = givenCourse.location;
                                courseDate.maxParticipants = givenCourse.maxparticipants;
                                courseDate.coursename = givenCourse.coursename;
                                //get course participant count
                                existingRegistrationsCount = _.where(existingCourseRegistrations, {"coursedateid": courseDate._id}).length;
                                //and save it on each course
                                courseDate.registeredParticipants = existingRegistrationsCount;
                                return courseDate;
                            });
                            vm.courseDatesReady = true;
                            //Opt-Out process is used for the general events (podium closing & Apéro)
                            vm.selectedCourses.push(_.findWhere(vm.courseDates, {courseid: PODIUM_COURSE_ID}));
                            vm.selectedCourses.push(_.findWhere(vm.courseDates, {courseid: APERO_COURSE_ID}));
                        });
                });
        });

        var rows = $('#schedule-container')[0].children;
        //Watcher for distributing the 'disabled' class on each field
        $scope.$watch(angular.bind(this, function () {
            //variable to watch
            return this.selectedCourses;
        }), function (newVal, oldVal) {

            //remove disabled class from all fields
            var selectedFields = $('.schedule-field.disabled');
            selectedFields = selectedFields.add($('.schedule-field.disabled-50'));
            var selectedField = {};
            var rowFieldElement = {};

            for (var m = 0; m < selectedFields.length; m++) {
                selectedField = selectedFields[m];
                angular.element(selectedField).removeClass('disabled');
                angular.element(selectedField).removeClass('disabled-50');
            }

            //add disabled class to selected fields
            angular.forEach(newVal, function (selectedCourse) {
                if (selectedCourse) {
                    var columnIndex = selectedCourse.columnIndex + 1;

                    //'podium closing' case
                    if (selectedCourse.courseid === PODIUM_COURSE_ID) {
                        for (var r = 0; r < rows[7].children.length; r++) {
                            if (r > 1 && r < 11) {
                                rowFieldElement = angular.element(rows[7].children[r]);
                                rowFieldElement.addClass('disabled');
                            }
                        }
                    } else if (selectedCourse.courseid === APERO_COURSE_ID) { //'apero riche' case
                        //iterate over each column
                        for (var p = 0; p < rows[0].children.length; p++) {
                            //only for valid fields
                            if (p > 1 && p < 11) {
                                //and mark 2 and a half rows of fields as disabled
                                for (var q = 8; q < 11; q++) {
                                    rowFieldElement = angular.element(rows[q].children[p]);
                                    //the third row is the half row
                                    if (q === 10) {
                                        rowFieldElement.addClass('disabled-50');
                                    } else {
                                        rowFieldElement.addClass('disabled')
                                    }
                                }
                            }
                        }
                    }
                    else {
                        //each respective rowfield
                        for (var n = 0; n < rows[selectedCourse.rowIndex].children.length; n++) {
                            if (n > 1 && n < 11) {
                                var rowField = rows[selectedCourse.rowIndex].children[n];
                                if (n !== columnIndex) {
                                    angular.element(rowField).addClass('disabled');
                                }
                            }
                        }

                        //each respective columnfield
                        for (var o = 0; o < rows.length; o++) {
                            if (o > 0) {
                                var relevantRowField = rows[o].children[columnIndex];
                                var appliedClass = o <= 9 ? 'disabled' : 'disabled-50';
                                angular.element(relevantRowField).addClass(appliedClass)
                            }
                        }
                    }
                }
            })
        }, true);

        var dayDate = [2016, 8, 16];
        //Object literal switch for setting a css-class which determines the (optical) length of a course
        var getClassByTimeInMin = function (timeInMin) {
            var getClassBy = {
                30: function () {
                    return "schedule-block-30"
                },
                45: function () {
                    return "schedule-block-45"
                },
                75: function () {
                    return "schedule-block-75"
                },
                default: function () {
                    return "schedule-block-30"
                }
            };
            if (getClassBy[timeInMin]) {
                return getClassBy[timeInMin]();
            } else {
                return getClassBy.default()
            }
        };

        function findCourseDate(hour, minute, location) {
            var date = angular.copy(dayDate);
            date.push(hour, minute);
            var startDate = moment(date).toDate();
            var courseDate = _.findWhere(vm.courseDates, {startsat: startDate.toJSON(), location: location});

            if (courseDate) {
                if (!courseDate.rowIndex) {
                    courseDate = setCourseDateCoordinations(courseDate, hour, minute, location);
                }
            }

            return courseDate;
        }

        function setCourseDateCoordinations(courseDate, hour, minute, location) {
            //plus one due to the bootstrap layout (consisting of 12 columns) where we leave the first and the last column empty
            var rowIndex = _.findIndex(vm.rows, {hour: hour, minute: minute});
            var columnIndex = _.findIndex(vm.columns, {label: location});

            courseDate.rowIndex = rowIndex;
            courseDate.columnIndex = columnIndex;

            return courseDate;
        }

        function getScheduleClass(hour, minute, location) {
            var courseDate = findCourseDate(hour, minute, location);
            //if there is a course
            if (courseDate) {
                //check if course is opt-in or opt-out
                var isOptOutCourse = courseDate.courseid === PODIUM_COURSE_ID || courseDate.courseid === APERO_COURSE_ID;
                var optOutCourse = isOptOutCourse ? "selected" : "";
                var courseUnavailable = courseDate.maxParticipants <= courseDate.registeredParticipants ? "unavailable" : "";
                //get course duration in minutes
                courseDate.durationInMin = moment(courseDate.endsat).diff(moment(courseDate.startsat)) / 1000 / 60;

                return "schedule " +
                    getClassByTimeInMin(courseDate.durationInMin) + " " +
                    optOutCourse +
                    courseUnavailable
            }
        }

        function prepareScheduleData(hour, minute, location, rowIndex, columnIndex) {
            var courseDate = findCourseDate(hour, minute, location);

            if (courseDate) {
                //change the tooltip according to the status of the course
                var tooltipContent = courseDate.maxParticipants <= courseDate.registeredParticipants ? "Platz ausgebucht!" : "Platz verfügbar";
                //TODO: change color of the circle according to the status of registrations
                return "<div class='schedule-course-title'>" +
                    "<span class='course-name'>" + courseDate.coursename + "</span>" +
                    "</div>" +
                    "<div class='schedule-course-length'>" +
                    "<div class='course-room'><i class='fa fa-map-marker' aria-hidden='true'></i> " + courseDate.location + "</div>" +
                    "<div class='course-time'><i class='fa fa-clock-o' aria-hidden='true'></i>&nbsp;&nbsp;" + courseDate.durationInMin + "'</div></div>" +
                    "<i class='fa fa-circle schedule-availability-sign' aria-hidden='true'" +
                    "uib-tooltip='" + tooltipContent + "' tooltip-placement='bottom' tooltip-append-to-body='true' tooltip-popup-delay='300'></i>"
            } else {
                return "";
            }
        }

        function selectCourse(hour, minute, location, $event) {
            var element = $event.currentTarget;
            //if class is set, course is unavailable
            var courseUnavailable = _.contains(element.classList, "unavailable");

            if (!courseUnavailable) {
                var courseDate = findCourseDate(hour, minute, location);
                var scheduleContainer = $('#schedule-container')[0];
                var rows = scheduleContainer.children;

                //toggle 'selected' class
                angular.element(element).toggleClass("selected");

                if (_.contains(element.classList, "selected")) {
                    //add course
                    vm.selectedCourses.push(courseDate);
                } else {
                    //remove course
                    vm.selectedCourses = _.without(vm.selectedCourses, _.findWhere(vm.selectedCourses, courseDate));
                }

                //reset duplicated row & column selection
                if (vm.selectedCourses.length > 0) {
                    //row
                    var existingRowSelection = _.findWhere(_.without(vm.selectedCourses, _.findWhere(vm.selectedCourses, {
                        rowIndex: courseDate.rowIndex,
                        columnIndex: courseDate.columnIndex
                    })), {rowIndex: courseDate.rowIndex});

                    if (existingRowSelection) {
                        var existingSelectionRow = rows[existingRowSelection.rowIndex];
                        var existingRowSelectionElement = angular.element(existingSelectionRow.children[existingRowSelection.columnIndex + 1].children[0]);

                        vm.selectedCourses = _.without(vm.selectedCourses, existingRowSelection);
                        existingRowSelectionElement.removeClass('selected');
                        existingRowSelectionElement.addClass('disabled')
                    }

                    //column
                    var existingColumnSelection = _.findWhere(vm.selectedCourses, {columnIndex: courseDate.columnIndex});
                    if (existingColumnSelection && courseDate.courseid === existingColumnSelection.courseid && courseDate._id !== existingColumnSelection._id) {
                        var existingCourseRowElement = rows[existingColumnSelection.rowIndex];
                        var existingCourseElement = angular.element(existingCourseRowElement.children[existingColumnSelection.columnIndex + 1].children[0]);
                        //remove course from selectionlist
                        vm.selectedCourses = _.without(vm.selectedCourses, existingColumnSelection);

                        //remove the css-selection
                        existingCourseElement.removeClass('selected');
                        existingCourseElement.addClass('disabled');
                    }
                }
            } else {
                $event.stopPropagation();
            }
        }

        function registerSchedule(selectedCourses) {
            var registeredCourses = [];
            console.log(selectedCourses);
            // TODO: Check if each course is still available and register,
            // TODO: otherwise display a message and remove the unavailable flag for the entry

            var deferred = $q.defer();
            var promises = [];

            angular.forEach(selectedCourses, function (selectedCourse) {
                var courseRegistrationData = {
                    coursedateid: "",
                    userid: ""
                };

                crudService.getCourseRegistrationsByCourseDateId(selectedCourse._id)
                    .then(function (success) {
                        registeredCourses = success.data;
                    }, function (error) {
                        deferred.reject("Getting courseRegistrations for '" + selectedCourse._id + "' failed");
                        console.log(error);
                    }).finally(function () {
                    //if the course is already full start 'abort' action
                    if (registeredCourses.length >= selectedCourse.maxParticipants) {
                        deferred.reject("Max participant count have already been reached");
                    } else {
                        courseRegistrationData.coursedateid = selectedCourse._id;
                        courseRegistrationData.userid = vm.currentUser._id;
                        promises.push(crudService.createCourseRegistration(courseRegistrationData))
                    }
                });
                //if all courses are done
            });
            //Create each course-registration
            $q.all(promises)
                .then(function (success) {
                    vm.scheduleCommitted = true;
                    deferred.resolve("Course-Program registered", success)
                }, function (error) {
                    console.log("couldn't register schedule", error);
                    //TODO: refresh available courses and remove fully booked ones
                    deferred.reject("Could not fetch all course data");
                });
            //resolve the registerSchedule async task
            deferred.promise
                .then(function (success) {
                    console.log(success);
                    vm.scheduleCommitted = true;
                    vm.scheduleCommittedText = "<span>Die Termineingabe war erfolgreich!</span><br><br>" +
                        "Ihr Tagesplan wird Ihnen in Kürze als <strong>.pdf</strong> und <strong>.ics</strong> per Mail" +
                        "zugestellt";
                }, function (error) {
                    console.log(error);
                })
        }

        function userRegistered() {
            //user is registered if currentUser is not empty
            var userRegistered = !_.isEmpty(vm.currentUser);
            if (!userRegistered) {
                vm.currentUser = dataService.user;
                userRegistered = !_.isEmpty(vm.currentUser);
                return userRegistered
            }
            return userRegistered
        }

        vm.getScheduleClass = getScheduleClass;
        vm.prepareScheduleData = prepareScheduleData;
        vm.selectCourse = selectCourse;
        vm.registerSchedule = registerSchedule;
        vm.userRegistered = userRegistered;
    }

    function crudService($http) {
        var service = {};

        service.createUser = function (data) {
            return $http({
                method: 'Post',
                url: '/users',
                data: data
            });
        };

        service.createCourseDate = function (data) {
            return $http({
                method: 'Post',
                url: '/courseDates',
                data: data
            })
        };

        service.getCourses = function () {
            return $http({
                method: 'Get',
                url: '/courses'
            })
        };

        service.getCourseDates = function () {
            return $http({
                method: 'Get',
                url: '/courseDates'
            })
        };

        service.readUserByEmail = function (email) {
            return $http({
                method: 'Get',
                url: '/userByEmail/' + email
            })
        };

        service.getCourseRegistrations = function () {
            return $http({
                method: 'Get',
                url: '/courseRegistrations'
            })
        };

        service.createCourseRegistration = function (data) {
            return $http({
                method: 'Post',
                url: '/courseRegistrations',
                data: data
            })
        };

        service.getCourseRegistrationsByCourseDateId = function (courseDateId) {
            return $http({
                method: 'Get',
                url: '/courseRegistrations/' + courseDateId
            })
        };

        return service;
    }

    function dataService() {
        var service = {};
        var courseDates = [];
        var userData = undefined;

        service.saveCourseDates = function (dates) {
            courseDates = courseDates.concat(dates);
        };
        service.getCourseDates = function () {
            return courseDates;
        };
        service.user = {};

        return service;
    }

    function RouteProvider($routeProvider, $locationProvider) {
        $routeProvider
            .when('/', {
                template: ' ',
                controller: 'MainController',
                controllerAs: 'main'
            })
            .otherwise({
                redirectTo: '/'
            });

        $locationProvider.html5Mode(true);
    }
//In order to load html dynamically and compile it
    function compileHelperDirective($compile) {
        return function (scope, element, attrs) {
            scope.$watch(
                function (scope) {
                    // watch the 'compile' expression for changes
                    return scope.$eval(attrs.sffCompile);
                },
                function (value) {
                    // when the 'compile' expression changes
                    // assign it into the current DOM
                    element.html(value);

                    // compile the new DOM and link it to the current
                    // scope.
                    // NOTE: we only compile .childNodes so that
                    // we don't get into infinite loop compiling ourselves
                    $compile(element.contents())(scope);
                }
            );
        };
    }

//function ThemeConfiguration ($mdThemingProvider){
//    //extend the blue theme with custom colors
//    var carnetBlueMap = $mdThemingProvider.extendPalette('blue', {
//        '900': '00658E',
//        '500': '2185AB',
//        '300': '00AADD',
//        '100': '3fac21',
//        'contrastDefaultColor': 'dark',
//        'contrastLightColors': ['900', '500', '300', '100']
//    });
//    //extend the red theme with custom colors
//    var carnetRedMap = $mdThemingProvider.extendPalette('red', {
//        '900': 'D71920',
//        '500': 'f57C00',
//        'A100': 'ffc107',
//        'contrastDefaultColor': 'dark',
//        'contrastLightColors': ['800', '900', 'A100']
//    });
//
//    //Register the new color palette map with the name <code>carnetBlue</code>
//    $mdThemingProvider.definePalette('carnetBlue', carnetBlueMap);
//    //Register the new color palette map with the name <code>carnetRed</code>
//    $mdThemingProvider.definePalette('carnetRed', carnetRedMap);
//    //Register others palette
//    //$mdThemingProvider.definePalette('carnetOthers', carnetOthers);
//
//    //define the color palettes for each
//    $mdThemingProvider.theme('default')
//        .primaryPalette('carnetBlue', {
//            'default': '900',
//            'hue-1': '500',
//            'hue-2': '300',
//            'hue-3': '100'
//        })
//        .accentPalette('grey', {
//            'default': '700',
//            'hue-1': '50',
//            'hue-2': 'A100' //white
//        })
//        .warnPalette('carnetRed', {
//            'default': '900',
//            'hue-1': 'A100',
//            'hue-2': '500'
//        })
//        .backgroundPalette('grey', {
//            //'color' for element backgrounds
//            'default': 'A100',
//            //color for background (speaking of the body element)
//            'hue-1': '50',
//            'hue-2': '600',
//            'hue-3': '500'
//        });
//}

//function IconConfiguration ($mdIconProvider) {
//    $mdIconProvider
//        .defaultIconSet('img/mdi.svg')
//}

    function underscoreLoader() {
        return window._;
    }

    sffApp
    //.config(ThemeConfiguration)
    //.config(IconConfiguration)
        .config(RouteProvider)
        .directive('sffCompile', compileHelperDirective)
        .factory('_', underscoreLoader)
        .controller('MainController', MainController)
        .controller('FormController', FormController)
        .controller('ScheduleController', ScheduleController)
        .service('crudService', crudService)
        .service('dataService', dataService);
})();

