(function(module) {
try {
  module = angular.module('SffLandingpageApp');
} catch (e) {
  module = angular.module('SffLandingpageApp', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('contentTemplate.html',
    '<div layout="column" flex="100"><md-content></md-content><md-content></md-content><md-content></md-content><md-content></md-content><md-content></md-content><md-button ng-href="#" scroll-to="logo">go to logo</md-button><div style="min-height: 2000px;height: 2000px">Lots of space....</div><div layout="column"><img src="img/Logo_SFF.png" alt="Swiss Fleet Forum Logo"> <img id="logo" src="img/Logo_SFF.png" alt="Swiss Fleet Forum Logo"></div></div>');
}]);
})();
